import { db } from './firebaseConfig';
import { collection, getDocs, addDoc, updateDoc, doc } from 'firebase/firestore';

const invitationsCollectionRef = collection(db, "invitations");

export const getInvitations = async () => {
  const data = await getDocs(invitationsCollectionRef);
  return data.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const addInvitation = async (newInvitation) => {
  return await addDoc(invitationsCollectionRef, newInvitation);
};

export const updateInvitation = async (id, updatedInvitation) => {
  const InvitationDoc = doc(db, "invitations", id);
  return await updateDoc(InvitationDoc, updatedInvitation);
};