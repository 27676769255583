import './App.css';
import FirstPage from './components/FirstPage';

function App() {
  return (
    <div>
      <FirstPage />
    </div>
  );
}

export default App;
