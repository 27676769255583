import React, { useState } from 'react';
import '../style/firstPage.css';
import tentImageDesktop from "../img/tentDesktop.jpg";
import tentImageMobile from "../img/tentMobile.jpg";
import abc from "../sounds/sound.mp3";
import HomePage from './HomePage';

const FirstPage = () => {
    const [animationState, setAnimationState] = useState("initial");
    const isMobile = window.innerWidth <= 768;
    const [showPopup, setShowPopup] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState("./duvar1.jpg");  // Yeni eklenen durum değişkeni

    const currentTentImage = isMobile ? tentImageMobile : tentImageDesktop;

    const handleClick = () => {
      if (animationState === "initial") {
        setAnimationState("zooming");
        document.getElementById('getaudio').play();
        setTimeout(() => {
          setAnimationState("showSecond");
        }, 4000);
      }
    };
  
    return (
        <div className="firstPageContainer" onClick={handleClick}>
             <div className="container" onClick={handleClick}>
        <div className={`contentWrapper ${showPopup ? 'blurred' : ''}`} style={{ backgroundImage: `url(${backgroundImage})` }}>
            {animationState !== "showSecond" ? (
                <img
                  src={currentTentImage}
                  alt="First"
                  className={`image ${animationState === "zooming" ? "zoom" : ""}`}
                />
            ) : ( <HomePage /> )}
        </div>
        <audio id='getaudio' autoPlay={false} controls={false} loop={true}>
            <source type="audio/mp3" src={abc} />
        </audio>
    </div>
        </div>
    );
}

export default FirstPage;
