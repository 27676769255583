import React, { useEffect, useState } from 'react';
import '../style/invitationPage.css';
import letter from '../img/zarf.png';
import { getInvitations } from '../config/invitationService';

const Invitations = () => {
    const [isNoteVisible, setIsNoteVisible] = useState(false);
    const [pastInvitations, setPastInvitations] = useState([]);
    const [upcomingInvitations, setUpcomingInvitations] = useState([]);
  
    const toggleNote = () => {
      setIsNoteVisible(!isNoteVisible);
    };
  
    useEffect(() => {
      const fetchInvitations = async () => {
        const fetchedInvitations = await getInvitations();
        const now = new Date();
        const past = [];
        const upcoming = [];
  
        fetchedInvitations.forEach(invitation => {
          const invitationDate = invitation.date.toDate();
          if (invitationDate < now) {
            past.push(invitation);
          } else {
            upcoming.push(invitation);
          }
        });
  
        setPastInvitations(past);
        setUpcomingInvitations(upcoming);
      };
  
      fetchInvitations();
    }, []);
  
    // Firestore'dan gelen Timestamp'ı gün (sayı) ay (yazı olarak) yıl (sayı olarak) formatında dönüştürme
    const formatDate = (timestamp) => {
      if (!timestamp) return '';
  
      // Ay isimlerini içeren bir dizi
      const months = [
        "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
        "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
      ];
  
      // Timestamp'ı Date nesnesine çevir
      const date = timestamp.toDate();
  
      // Gün, ay ve yıl bilgisini al
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
  
      // Belirtilen formatta bir string döndür
      return `${day} ${month} ${year}`;
    };
  
  
    return (
      <div className="letter-container">
        <img
          src={letter}
          alt="Letter"
          className="letter-icon"
          onClick={toggleNote}
        />
        
        {isNoteVisible && (
          <div id="parchment" className="note-modal">
            <h5>Gelecek Davetler</h5>
            {upcomingInvitations.map((invitation) => (
              <p className="dateList" key={invitation.id}>
                {invitation.location} - {formatDate(invitation.date)}
              </p>
            ))}
            <h5>Geçmiş Davetler</h5>
            {pastInvitations.map((invitation) => (
              <p className="dateList" key={invitation.id}>
                {invitation.location} - {formatDate(invitation.date)}
              </p>
            ))}
          </div>
        )}
      </div>
    );
}

export default Invitations;