import React, { useState } from 'react';
import '../style/rulesPage.css';
import ruleIcon from '../img/biby.jpg';
import rules from '../img/popup1.jpeg';
import closeIcon from '../img/x-button.png';  // Kapatma ikonu için dosya yolu

const Rules = () => {
    const [isDetailVisible, setIsDetailVisible] = useState(false);

    const toggleDetail = () => {
        setIsDetailVisible(!isDetailVisible);
    };

    return (
        <div className="ruleIconContainer">
            <button className="rule-icon-button" onClick={toggleDetail}>
                <img src={ruleIcon} alt="Info Icon" className="rule-icon" />
            </button>

            {isDetailVisible && (
                <div className="detail-modal">
                    <button className="close-button" onClick={toggleDetail}>
                        <img src={closeIcon} alt="Close" className="close-icon" />
                    </button>
                    <img src={rules} alt="Rules" className="detail-icon" />
                </div>
            )}
        </div>
    );
};

export default Rules;
