import React from 'react';
import '../style/littleIconsPage.css';
import Rules from './Rules';
import Invitations from './Invitations';

const LittleIcons = () => {
    return (
        <div className="littleIconsontainer">
            <Rules />
            <Invitations />
        </div>
    );
}

export default LittleIcons;