// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore} from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCAjkMFBK3BDHaT7WAtcuI-QLdOeY81z_g",
  authDomain: "circus-of-company.firebaseapp.com",
  projectId: "circus-of-company",
  storageBucket: "circus-of-company.appspot.com",
  messagingSenderId: "244671908884",
  appId: "1:244671908884:web:ed887bc0382393ebecab01",
  measurementId: "G-L4CE4ZY2W4"
};
const app = initializeApp(firebaseConfig);

// Firestore örneğini al (modular API kullanılarak)
const db = getFirestore(app);

export { db }; 
