import React from 'react';
import '../style/framesPage.css';
import bibi from '../img/BIBY1.png'; 
import bibi2 from '../img/BIBY2.png'; 

const Frames = () => {
    return (
        <div className="frame">
          <a href="https://www.bkmkitap.com/bazi-insanlar-boyle-yasar-834098" target="_blank" rel="noopener noreferrer" className="image-link">
            <figure className="wave">
              <img src={bibi} alt="First Image" />
              <div class="buy-button">Satın Al</div>
            </figure>
          </a>
          <a href="https://www.bkmkitap.com/bazi-insanlar-boyle-yasar-853352" target="_blank" rel="noopener noreferrer" className="image-link">
            <figure className="wave">
                <img src={bibi2} alt="Second Image"/>
                <div class="buy-button">Satın Al</div>
            </figure>
          </a>
        </div>
    );
}

export default Frames;
