import React from 'react';
import '../style/homePage.css';
import Frames from './Frames';
import LittleIcons from './LittleIcons';

const HomePage = () => {
    return (
        <div className="homePageContainer">
            <Frames />
            <LittleIcons />
        </div>
    );
}

export default HomePage;
